<template>
    <v-container fluid fill-height class="auth_page">
        <v-row justify="center">
            <v-card class="elevation-1">
                <v-container fluid class="pt-0 pb-1">
                    <AuthForm :header="$t('auth.access_account')" :is_loading="loading" @submit="submit">
                        <template slot="content">
                            <v-col cols="12">
                                <v-text-field id="email" name="email" type="email" class="custom_field"
                                              v-model="user.email" :label="$t('general.email')"
                                              prepend-inner-icon="person" outlined clearable
                                              @input="$v.user.email.$touch()" @blur="$v.user.email.$touch()"
                                              :error-messages="fieldErrors('email')">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field id="password" name="password" type="password" class="custom_field"
                                              v-model="user.password" :label="$t('general.password')"
                                              prepend-inner-icon="lock" outlined clearable
                                              @input="$v.user.password.$touch()" @blur="$v.user.password.$touch()"
                                              :error-messages="fieldErrors('password')">
                                </v-text-field>
                            </v-col>
                        </template>
                        <template slot="links">
                            <v-col cols="12" class="text-md-left">
                                <router-link class="auth_link" :to="{ name: 'forgot-password'}">
                                    {{$t('auth.forgot_password')}}
                                </router-link>
                            </v-col>
                        </template>
                    </AuthForm>
                </v-container>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, minLength, email} from 'vuelidate/lib/validators'
    import AuthForm from './../../components/AuthForm.vue'

    export default {
        components: {
            AuthForm
        },
        mixins: [validationMixin],
        validations: {
            user: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                }
            },
        },
        data: () => ({
            // login
            user: {
                email: null,
                password: null,
            },
            loading: false
        }),
        methods: {
            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.user[prop].$dirty) return errors;

                // add email rules
                if (prop === 'email') {
                    !this.$v.user[prop].email && errors.push(this.$t('validation.email'));
                }

                // add password rules
                if (prop === 'password') {
                    !this.$v.user[prop].minLength && errors.push(this.$tc('validation.min_length', 1, {min: this.$v.user[prop].$params.minLength.min}));
                }

                !this.$v.user[prop].required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // make API call to authenticate user
            async submit() {
                this.$v.user.$touch();

                // login data are valid
                if (!this.$v.user.$invalid) {
                    this.loading = true;

                    let response = await this.$store.dispatch('apiRequest', {
                        method: 'post', url: 'login', data: {
                            email: this.user.email, password: this.user.password
                        }, hide_response: true
                    });

                    this.loading = false;

                    // successful response
                    if (!response.response) {

                        // save access_token to IndexedDB
                        let access_token = await this.$store.state.app_store.setItem('access_token', response.data.token);

                        // mutate access_token in store
                        await this.$store.commit('mutateAccessToken', access_token);
                        await this.$store.dispatch('setAuthHeader', access_token);

                        // save in store user details
                        await this.$store.commit('mutateUserDetails', response.data.user_details);

                        // save in store permissions
                        await this.$store.commit('mutatePermissions', response.data.permissions);

                        this.clearForm();

                        setTimeout(() => {
                            this.$router.push('/');
                        }, 500);
                    }
                }
            },
            // clear form data
            clearForm() {
                this.$v.user.$reset();

                for (let prop in this.user) {
                    this.user[prop] = null;
                }
            }
        }
    }
</script>